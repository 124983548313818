/*eslint-disable */
// Set webpack public path for async loading.
__webpack_public_path__ = window.themeUrl + 'dist/';

import $ from 'jquery'

// workaround for ie11 and dynamic import
// @see https://github.com/babel/babel/issues/10140
import 'core-js/modules/es.array.iterator';

// DOM polyfills are not part of core-js.
import 'classlist.js';
import 'element-closest-polyfill';
import 'objectFitPolyfill';

import SmoothAnchors from 'utils/helpers/smooth-anchors';
import Module from 'components/module/module';
import Reframe from 'utils/helpers/reframe';
import './main.css';

objectFitPolyfill();

const body = document.body;

const modules = new Module(body)

function logModuleLoadFailed(error) {
  console.warn('An error occurred while loading the component');
  console.error(error);
}

function initStimulPlugins(context) {
  // Init small helpers.
  new SmoothAnchors(context);
  new Reframe(context);

  // Load some components javascript files.
  if (context.querySelector('[data-gmap]')) {
    import(/* webpackChunkName: "gmaps" */ 'components/gmaps/gmaps').then(Gmaps => {
      new Gmaps.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('[data-lightbox]')) {
    import(/* webpackChunkName: "lightbox" */ 'components/lightbox/lightbox').then(Lightbox => {
      new Lightbox.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('[data-menu-toggle]')) {
    import(/* webpackChunkName: "menu-toggle" */ 'components/menu-toggle/menu-toggle').then(MenuToggle => {
      new MenuToggle.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('[data-slideshow]')) {
    import(/* webpackChunkName: "slideshow" */ 'components/slideshow/slideshow').then(Slideshow => {
      context.querySelectorAll('[data-slideshow]').forEach(el => new Slideshow.default(el));
      // new Slideshow.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector("[data-comment-reply]")) {
    import(/* webpackChunkName: "comment" */ 'components/comment/comment').then(Comment => {
        new Comment.default(context);
      }).catch(logModuleLoadFailed);
  }

  // Load extra modules
  if (context.querySelector('.rellax')) {
    import(/* webpackChunkName: "parallax" */ 'utils/helpers/parallax').then(Parallax => {
      new Parallax.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('[data-sticky-header]')) {
    import(/* webpackChunkName: "sticky-header" */ 'utils/helpers/sticky-header').then(StickyHeader => {
      new StickyHeader.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('[data-animation]')) {
    import(/* webpackChunkName: "scroll-animation" */ 'utils/helpers/scroll-animation').then(ScrollAnimation => {
      new ScrollAnimation.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('[data-imagesloaded]')) {
    import(/* webpackChunkName: "imagesloaded" */ 'utils/helpers/imagesloaded').then(ImagesLoaded => {
      new ImagesLoaded.default(context);
    }).catch(logModuleLoadFailed);
  }

  // Modules & components js
  if (context.querySelector('.media-item')) {
    import(/* webpackChunkName: "MediaItem" */ 'components/media-item/media-item').then(MediaItem => {
      new MediaItem.default(context);
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('[data-accordion]')) {
    import(/* webpackChunkName: "accordion" */ 'components/accordion/accordion').then(Accordion => {
      context.querySelectorAll('[data-accordion]').forEach(el => new Accordion.default(el));
    }).catch(logModuleLoadFailed);
  }
  if (context.querySelector('.gform_wrapper')) {
    import(/* webpackChunkName: "Form" */ 'components/form/form').then(Form => {
      new Form.default(context);
    }).catch(logModuleLoadFailed);
  }
}

initStimulPlugins(document.querySelector('html'));
// Make function globally available.
window.initStimulPlugins = initStimulPlugins;

const onScroll = () => {
  // add has-scrolled to body, used in site-header.css
  body.classList.toggle('has-scrolled', window.scrollY > window.innerHeight * 0.9);
}

// If url has hash, add .m-unpinned to body.
// safari doesn't play the scroll-to with anchor, the header was not hidden.
// @see https://gitlab.com/stimul-ch/react/-/issues/56
if (window.location.hash) {
  body.classList.add('m-unpinned');
}

window.addEventListener('scroll', onScroll);
onScroll();

const productsWrap = document.querySelectorAll('.products');
const cartSvg = document.querySelector('.cart-menu-item svg')
if (productsWrap.length && cartSvg) {

  productsWrap.forEach((el) => {
    const prices = el.querySelectorAll('.wcpbc-price');
    prices.forEach((price) => {
      // add cloned svg to product
      const svg = cartSvg.cloneNode(true);
      svg.classList.add('fill-current')
      console.log(svg)


      var config = { attributes: true, childList: true };
      const setSvg = () => {
        if (price.querySelector('svg')) return
        if (!price.querySelector('.amount')) return
        price.appendChild(svg)
      }
      setSvg()
      var observer = new MutationObserver(setSvg);
      observer.observe(price, config);
    })
  })
}
