/*
* Not much to say about this one. The following line should be self-explanatory
* ----- const scrolls = new SmoothScroll('[data-smoothscroll]'); -----
*
* Official documentation: https://github.com/cferdinandi/smooth-scroll
  * NPM repo: https://www.npmjs.com/package/smooth-scroll
  *
*/

// Import NPM library
const SmoothScroll = require('smooth-scroll');

export default class SmoothAnchors {
  constructor() {
    new SmoothScroll('[data-smooth-anchors]');
  }
}
