function isElementInViewport(el) {
  const rect = el.getBoundingClientRect();

  return rect.bottom > 0 &&
      // rect.top < window.innerHeight - 200
      rect.top < window.innerHeight * 0.5
}

const getItemPercent = (el) => {
  const rect = el.getBoundingClientRect()
  const windowHeight = window.innerHeight
  // start at 80% of the viewport and ends at 50%
  const start = windowHeight * 0.6
  const end = windowHeight * 0.5
  // When rect.top is at end percent = 0, when rect.top is at start percent = 1
  let percent = (end - rect.top) / (start - end)
  // return percent
  // percent = percent * -1
  return Math.max(0, Math.min(1, percent))
}

const getColor = (percent) => {
  // Rertun hsl color with luminosity based on percent
  return `hsl(0, 0%, ${100 - percent * 100}%)`
}

function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		clearTimeout(timeout);
		timeout = setTimeout(function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		}, wait);
		if (immediate && !timeout) func.apply(context, args);
	};
}

function toggleMode(isDark) {
  document.body.classList.toggle('is-dark', isDark)
}

const toggleModeDebounced = debounce(toggleMode, 25)

export default class Module {
  constructor(context) {
    this.modules = context.querySelectorAll('.site-content .module, .site-footer');
    window.addEventListener('scroll', () => this.update())
    window.addEventListener('resize', () => this.update())
    this.update()
  }

  update() {
    let lastVisibleModule = null
    let lastVisibleModuleIndex = null
    let prevModule = null
    for (let i = 0; i < this.modules.length; i++) {
      const module = this.modules[i]
      let visible = isElementInViewport(module)
      if (visible) {
        lastVisibleModule = module
        lastVisibleModuleIndex = i
        module.classList.add('is-visible')
      }
      // module.classList.toggle('is-not-in-viewport', !visible)
    }
    for (let i = 0; i < this.modules.length; i++) {
      const module = this.modules[i]
      const isLast = module === lastVisibleModule
      module.classList.toggle('is-not-in-viewport', !isLast)
    }
    let isDark = false
    let prevIsDark = false
    if (lastVisibleModuleIndex > 0) {
      prevModule = this.modules[lastVisibleModuleIndex - 1]
      if (prevModule && prevModule.hasAttribute('data-is-dark')) {
        prevIsDark = true
      }
    }
    let percent = 1
    if (lastVisibleModule) {
      percent = getItemPercent(lastVisibleModule)
    }

    if (lastVisibleModule && lastVisibleModule.hasAttribute('data-is-dark')) {
      isDark = true
    }

    let darkBgPercent = 0
    if (isDark && prevIsDark) {
      darkBgPercent = 1
    }
    if (isDark && !prevIsDark) {
      darkBgPercent = percent
    }
    if (!isDark && prevIsDark) {
      darkBgPercent = 1 - percent
    }

    // If we have scrolled the page to the bottom - 100px, start to go black
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100) {
      percent = (window.innerHeight + window.scrollY - (document.body.offsetHeight - 100)) / 100
      darkBgPercent = Math.max(darkBgPercent, Math.max(0, Math.min(1, percent)))
    }

    // Prevent background change on checkout pages
    if (document.body.classList.contains('woocommerce-checkout') ||
        document.body.classList.contains('woocommerce-cart')) {
      darkBgPercent = 0;
    }

    let offset = 0
    if (darkBgPercent > 0.47 && darkBgPercent < 0.53) {
      offset = 0.04
    }

    // Set css variable color on body
    document.body.style.setProperty('--theme-default-color', getColor(1 - darkBgPercent + offset))
    document.body.style.setProperty('--theme-inverse-color', getColor(darkBgPercent - offset))
    // toggleMode(isDark)
    // toggleModeDebounced(isDark)
  }
}
